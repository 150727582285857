import Vue from "vue";
import VueRouter from "vue-router";
import userRoutes from "./user";

Vue.use(VueRouter);

const generalRoutes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Homepage/Home.vue"),
    meta: {
      title: "Ajay Thapa Magar",
    },
  },
  {
    path: "/about-me",
    name: "AboutMe",
    component: () => import("../views/About/AboutMe.vue"),
  },
  {
    path: "/course/:slug/:id",
    name: "CourseDetail",
    component: () => import("../views/Courses/CourseDetail.vue"),
  },
  {
    path: "/courses",
    name: "Courses",
    component: () => import("../views/Courses/CourseList.vue"),
  },
  {
    path: "/blogs",
    name: "BlogList",
    component: () => import("../views/Blogs/BlogList.vue"),
  },
  {
    path: "/blog/:slug",
    name: "BlogDetail",
    component: () => import("../views/Blogs/BlogDetail.vue"),
  },
  {
    path: "/assets",
    name: "AssetsList",
    component: () => import("../views/Shop/AssetsList.vue"),
  },
  {
    path: "/user-profile",
    name: "UserProfile",
    component: () => import("../views/User/UserProfile.vue"),
  },
  {
    path: "/checkout",
    name: "Checkout",
    component: () => import("../components/Checkout.vue"),
  },
  {
    path: "/not-allowed",
    name: "NotAllowed",
    component: () => import("../components/NotAllowed.vue"),
    meta: { hideHeaderFooter: true },
  },
];
const routes = generalRoutes.concat(userRoutes);
const router = new VueRouter({
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  mode: "history",
  routes,
});
router.beforeEach((to, from, next) => {
  // Get the page title from the route meta data that we have defined
  const title = to.meta.title;
  //Take the title from the parameters
  const titleFromParams = to.params.pageTitle;
  // If the route has a title, set it as the page title of the document/page
  if (title) {
    document.title = title;
  }
  // If we have a title from the params, extend the title with the title
  // from our params
  if (titleFromParams) {
    document.title = `${titleFromParams} - ${document.title}`;
  }
  // Continue resolving the route
  next();
});
export default router;
