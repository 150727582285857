<template>
    <div>
        <div class="cartTitle">
            <h2>Cart</h2>
            <div @click="toggleSidebar" class="closeIcon">
                <span class="icon-close"></span>
            </div>
        </div>

        <!-- Cart Empty Condition Start -->

        <div v-if="cartQuantity === 0 " class="cartContent no-item-cart">
            <span class="icon-empty"></span>
            <p>There is no any items on cart</p>
            <button v-if="!isAuthenticated" class="univ-btn w-100" v-b-modal.authenticate>Login or Sign Up </button>
        </div> 
        <div v-else class="cartContent">
            <div class="cartList">
                <vue-scroll :ops="cartScroll">
                    <ul>
                        <li v-for="(item,i) in cartItems" :key="i" >
                            <!-- <router-link :to="{ name: 'CourseDetail', params: {slug:item.item_slug, id:item.item_id }}" > -->
                            <router-link  to="">
                                <div class="itemImage">
                                    <img :src="item.thumbnail">
                                </div>
                                <div class="itemContent">
                                    <div>
                                        <h3> {{ item.title }} </h3>
                                        <p>Type: <span class="brand-color">{{item.item_type}}</span></p>

                                        <template v-if="item.is_offer">
                                            <span class="price" ><del> {{item.display_price}} </del></span>
                                            <span class="price"> {{ item.display_offer_price }} </span>
                                        </template>
                                        <template v-else>
                                            <span class="price"> {{ item.display_price }} </span>
                                        </template>
                                    </div>
                                    <div class="cartRemove" @click="callRemoveCartPromise(item)" >
                                        <span class="icon-trash"></span>
                                    </div>
                                </div>
                            </router-link>
                        </li>
                    </ul>
                </vue-scroll>
            </div>
            <div class="cartAction">
                <div class="totalAmount">
                    <strong>Total:</strong>
                    <span class="brand-color"> {{ currency }} {{cartTotal}} </span>
                </div>
                <div class="checkoutOption">
                    <button class="univ-btn w-100" @click="redirctCheckout"> Checkout </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import store from "@/store"
import router from "@/router"
import { mapGetters, mapActions } from "vuex"
export default {
    name: 'Cart',
    data: () => ({
        cartScroll: {
            vuescroll: {
                mode: "native",
                wheelScrollDuration: "700",
            },
            scrollPanel: {
                maxHeight: 600,
            },
            bar: {
                background: "#ddd",
            },
        },
        userId: store.getters.user.id,
        isAuthenticated: store.getters.authenticated,
        currency: store.getters.currency,
    }),
    computed: {
        ...mapGetters(["cartItems", "cartTotal", "cartQuantity"]),
    },
    created() {
        let app = this;
        if(app.isAuthenticated){
            app.$store.dispatch("getCartItems",app.userId);
        }
    },
    methods: {
        toggleSidebar() {
            let app = this;
            app.$root.$emit("cart-toggle", false);
        },
        ...mapActions(["removeCartItem"]),
        callRemoveCartPromise(item){
            let app = this;
            app.removeCartItem({id:item.cartId,item_id:item.item_id,type:item.item_type})
            .then(() => {
                app.$root.$emit("cart-item-removed", true);
            }).catch(()=>{});
        },
        redirctCheckout(){
            let app = this;
            router.push({ name: "CartCheckout" })
            app.$root.$emit("cart-toggle", false);
        }
    }
}
</script>