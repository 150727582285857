<template>
  <div>
    <p> You are attempting to sign in with a different device. Do you want to continue? </p>
    <b-button class="univ-btn w-10 m-4" @click="confirmDeviceChange" > Yes </b-button>
    <b-button class="univ-btn w-10 m-2" @click="cancelDeviceChange"> No </b-button>
  </div>
</template>

<script>
export default {
  methods: {
    confirmDeviceChange() {
      this.$emit("change-device-modal", true);
    },
    cancelDeviceChange() {
      this.$emit("change-device-modal", false);
    },
  },
};
</script>
