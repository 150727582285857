<template>
    <div>
        <footer class="siteFooter">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="footer-block">
                            <div class="row">
                                <div class="col-xl-6">
                                    <ul>
                                        <li></li>
                                    </ul>
                                </div>

                                <div class="col-xl-6">
                                    <p> &copy; Ajay {{currentDate}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <AssetQuickViewModal v-if="quickViewStatus" :assetItem="assetItem" />
    </div>
</template>
<script>
import store from "@/store"
import AssetQuickViewModal from '@/views/Shop/partials/AssetQuickViewModal';
export default {
    name:'Footer',
    components:{AssetQuickViewModal},
    data() {
      return {
        isAuthenticated:store.getters.authenticated,
        assetItem:"",
        quickViewStatus:false,
        currentDate: new Date().getFullYear(),
      }
    },
    mounted() {
        let app = this;
        app.$root.$on("quickview-status", ($event) => {
            app.quickViewStatus = $event;
        });
        app.$root.$on("item-data", ($event) => {
            app.assetItem = $event;
        });
        
    },
}
</script>