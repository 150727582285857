<template>
  <div>
    <video ref="videoPlayer" @timeupdate="updateTime" @pause="onPause" controls autoplay></video>
  </div>
</template>

<script>
import axios from "axios";
import api from "@/services/api";
import dashjs from "dashjs";
import store from "@/store";
export default {
  props: {
    src: {
      type: String,
    },
    isPrivate: Boolean,
  },
  data() {
      return {
          currentTime: 0,
      };        
  },
  mounted() {
    // Initialize DASH player
    this.initializePlayer(this.src);
  },
  watch: {
    src(newValue) {
      // Re-initialize the DASH player when the src prop changes
      this.initializePlayer(newValue);
    },
  },
  methods: {
    initializePlayer(src) {
      if (this.player) {
        this.player.reset();
      }

      this.player = dashjs.MediaPlayer().create();
      this.player.initialize(this.$refs.videoPlayer, src);
      this.playerEventWatcher();
    },
    playerEventWatcher() {
      this.player.on(dashjs.MediaPlayer.events["PERIOD_SWITCH_STARTED"], () => {
        if (this.interval) {
          clearInterval(this.interval);
        }
        if (this.isPrivate) {
        this.interval = setInterval(() => {
          this.authenticateUser();
        }, 10000);
        }
      });
    },
    authenticateUser() {
      axios.post(api.getUrl("/check"),{userId:store.getters.user.id});
    },
    updateTime() {
        let app = this;
        // Access the video element using the $refs object
        const videoPlayer = app.$refs.videoPlayer;
        // Update the currentTime data property
        app.currentTime = app.secondsToHms(parseInt(videoPlayer.currentTime));
    },
    onPause() {
        let app = this;
        // The video has been paused, update the time in the database
        app.updateUserVideoPlayingStatus();
    },
    secondsToHms(d) {
        d = Number(d);
        let h = Math.floor(d / 3600);
        let m = Math.floor(d % 3600 / 60);
        let s = Math.floor(d % 3600 % 60);

        let hDisplay = h > 0 ? h +':': "00:";
        let mDisplay = m > 0 ? m + ':' : "00:";
        let sDisplay = s > 0 ? s : "00";
        return hDisplay + mDisplay + sDisplay; 
    },
    updateUserVideoPlayingStatus(){
      let app = this;
      console.log('Pause Time:' ,app.currentTime);
      app.$emit("video-timetracking", app.currentTime);
    },
  },
  beforeDestroy() {
    // Clean up DASH player when component is destroyed
    if (this.player) {
      this.player.reset();
    }
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.updateUserVideoPlayingStatus();
  },
};
</script>

<style>
/* Add any custom styles here */
</style>
