import axios from 'axios';
import api from '@/services/api'
const state = {
    courseReview:[],
}

const mutations = {
    UPDATE_COURSE_REVIEW (state, payload) {
        state.courseReview = payload;
    },
}

 const actions ={
    addCourseReview({ dispatch }, reviewItem) {
        axios.post(api.getUrl('/user/feedback'), reviewItem).then((response) => {
          if (response.data.success) {
            dispatch('getCourseReview', reviewItem.course_id);
          }
        });
      },
      getCourseReview({ commit }, course_id) {
        axios.get(api.getUrl(`/course/reviews?course_id=${course_id}`)).then((response) => {
          if (response.data.success) {
            commit('UPDATE_COURSE_REVIEW', response.data.data);
          }
        });
      },
 }

 const getters = {
    getReviewState: state => state.courseReview,
 }

 export default {
    state,
    mutations,
    actions,
    getters
}