<template>
  <div v-if="deviceChangeModal">
    <DeviceChange @change-device-modal="handleDeviceChange($event)" />
  </div>
  <div class="loginForm" v-else>
    <div class="form-group mb-3">
      <label>Email <span class="required-field">*</span></label>
      <input
        type="text"
        @keydown.enter="focusNext"
        ref="input-0"
        placeholder="Enter Email Address"
        class="univ-input"
        v-model="logingReq.email"
      />
      <span class="error-field" v-if="loginErrors.email"
        ><span class="icon-warning"></span> {{ loginErrors.email }}</span
      >
    </div>
    <div class="form-group mb-3">
      <label>Password <span class="required-field">*</span></label>
      <input
        type="password"
        @keydown.enter="focusNext"
        ref="input-1"
        placeholder="Enter Password"
        class="univ-input"
        v-model="logingReq.password"
      />
      <span class="error-field" v-if="loginErrors.password"
        ><span class="icon-warning"></span> {{ loginErrors.password }}</span
      >
    </div>
    <div class="form-group mb-4">
      <span class="error-message" v-if="loginErrors.message"
        ><span class="icon-warning"></span> {{ loginErrors.message }}</span
      >
      <p>
        <span class="brand-color text-cursor" @click="forgotPassword()">
          Forgot Passowrd?
        </span>
      </p>
    </div>
    <button class="univ-btn w-100 mb-4" @click="userLogin()" ref="input-2">
      <b-spinner v-if="loginLoader" small></b-spinner> Sign In
    </button>
  </div>
</template>
<script>
import axios from "axios";
import store from "@/store";

import api from "@/services/api";
import helper from "@/services/helper";
import DeviceChange from "./DeviceChange.vue";
export default {
  components: {
    DeviceChange,
  },
  data() {
    return {
      logingReq: {
        email: "",
        password: "",
        changeAgent: "",
      },
      loginErrors: {
        email: "",
        password: "",
        message: "",
      },
      loginLoader: false,
      deviceChangeModal: false,
      currentIndex: 0,
    };
  },
  methods: {
    closeModal() {
      let app = this;
      app.$bvModal.hide("authenticate");
    },
    userLogin() {
      let app = this;
      app.clearedError();
      app.loginLoader = true;
      axios.post(api.getUrl("/login"), app.logingReq).then((response) => {
        if (response.data.success == true) {
          app.closeModal();
          app.loginLoader = false;
          store.commit("setAuthentication", true);
          helper.setLoginDetails(response.data);
          app.$root.$emit("authentication-status", true);
        } else {
          if (response.data.message) {
            app.loginLoader = false;
            if (
              response.data.message ===
              "You are attempting to sign in with a different device."
            ) {
              this.deviceChangeModal = true;
            } else {
              app.loginErrors.message = response.data.message;
            }
          } else if (response.data.errors.length > 0) {
            app.loginLoader = false;
            response.data.errors.forEach(function (item) {
              if (item.key == "email") {
                app.loginErrors.email = item.message;
              } else if (item.key == "password") {
                app.loginErrors.password = item.message;
              }
            });
          }
        }
      });
    },
    clearedError() {
      let app = this;
      app.loginErrors.email = "";
      app.loginErrors.password = "";
      app.loginErrors.message = "";
    },
    forgotPassword() {
      let app = this;
      app.$emit("forgot-password-modal", true);
    },
    handleForgotPassword($event) {
      let app = this;
      app.passwordForgotModal = $event;
    },
    handleDeviceChange($event) {
      // let app = this;
      if (!$event) {
        this.deviceChangeModal = false;
      } else {
        this.logingReq.changeAgent = true;
        this.userLogin();
      }
    },
    focusNext() {
      let app = this;
      app.currentIndex += 1;
      const nextElement = app.$refs[`input-${app.currentIndex}`];
      if (nextElement) nextElement.focus();
    },
  },
};
</script>
