<template>
    <div>
        <template v-if="isAuthenticated">
            <template v-if="checkCartStatus && !status">
                <div class="mt-2 add-cart-link cursor-pointer univ-btn w-100" >
                    <a @click="callRemoveCartPromise(getSelecteItem)" > 
                        <b-spinner v-if="cartBtnLoading" small></b-spinner> 
                        <span class="icon-cart"></span><span > Remove Cart </span>
                    </a>
                </div>
            </template>
            <template v-else >
                <div v-if="!status" class="mt-2 add-cart-link cursor-pointer univ-btn w-100" >
                    <a @click="addCartItem(reqItem)" > 
                        <b-spinner v-if="cartBtnLoading" small></b-spinner> 
                        <span class="icon-cart"></span><span >Add to Cart</span>
                    </a>
                </div>
            </template>
        </template>
        <template v-else>
            <div class="mt-2 add-cart-link cursor-pointer univ-btn w-100" >
                <a v-b-modal.authenticate @click="actionBtn"> <span class="icon-cart"></span> Add to Cart </a>
            </div>
        </template>
    </div>
</template>

<script>
import store from "@/store"
import { mapGetters, mapActions } from 'vuex'
import Helper from "@/services/helper"
export default {
    name: "ActionCart",
    props:{
        itemData:{
            required: true,
            type:[Array,Object],
        }
    },
    data() {
        return {
            status: this.itemData.purchase,
            isAuthenticated: store.getters.authenticated,
            reqItem:{
                item_id: this.itemData.item_id,
                item_type: this.itemData.item_type,
                quantity: this.itemData.quantity,
                user_id: this.itemData.user_id
            },
            actionType:"",
        };        
    },
    computed: {
        ...mapGetters(["cartBtnLoading","getSelecteItem"]),
        checkCartStatus(){
            let app = this;
            let cartItems = store.getters.cartItems;
            return Helper.checkCurrentCourseStatus(cartItems, app.reqItem.item_id);
        },
    },
    mounted(){
        let app = this;
        app.$root.$on("authentication-status", ($event) => {
            app.isAuthenticated = $event;
        });
    },
    methods:{
        ...mapActions(["addCartItem","removeCartItem"]),
        callRemoveCartPromise(cartItem){
            let app = this;
            app.removeCartItem({id:cartItem.cartId, item_id:cartItem.item_id, type:cartItem.item_type }).then(() => {
                app.$root.$emit("cart-item-removed", true);
            }).catch(()=>{});
        },
        actionBtn(){
            this.actionType = "add-to-cart";
        }
    },
    watch: {
        itemData(value){
            let app = this;
            app.reqItem.item_id     = value.item_id;
            app.reqItem.item_type   = value.item_type;
            app.reqItem.quantity    = value.quantity;
            app.reqItem.user_id     = value.user_id;

            app.status  = value.purchase;
        },
        isAuthenticated(value) {
            let app = this;
            if(value && app.actionType == 'add-to-cart') {
                app.reqItem.user_id = store.getters.user.id;
                app.addCartItem(app.reqItem);
            }
        },
    },
}
</script>