<template>    
    <b-modal id="authenticate" centered @hidden="onModalClose">
        <div class="authenticationModal" v-if="!showSignup && !passwordForgotModal">
            <h2>Login</h2>
            <LoginForm @forgot-password-modal="handleForgotPassword($event)" />
            <div class="text-center">
                <p>Don't have an account? <span class="brand-color signupBtn" @click="showSignup = true">Sign Up</span></p>
            </div>
        </div>
        <div v-if="showSignup && !passwordForgotModal" class="signup-wrap authenticationModal">
            <div @click="showSignup = false" class="align-items-center d-flex mb-3 authBack-wrap">
                <span class="authBack-btn">
                    <span class="icon-arrow"></span>
                </span> 
                Login
            </div>
            <RegisterForm />
        </div>
        <div v-if="passwordForgotModal" class=" ">
            <ForgotPassword/>
        </div>
    </b-modal>
</template>
<script>

import LoginForm from "@/views/Signin/LoginForm"
import RegisterForm from "@/views/Signup/RegisterForm"
import ForgotPassword from '../views/Signin/ForgotPassword'
export default {
    name: 'AuthModal',
    components:{
        RegisterForm,
        LoginForm,
        ForgotPassword,
    },
    data() {
        return {
            showSignup: false,
            passwordForgotModal:false,
        };
    },
    mounted() {
        let app = this;
        app.$root.$on("redirect-to-login", ($event) => {
            app.passwordForgotModal = $event;
        });
    },
    methods: {
        onModalClose() {
            this.showSignup = false;
        },
        handleForgotPassword($event){
            let app = this;
            app.passwordForgotModal = $event;
        },
    },
}
</script>