<template>
  <div id="app">
    <SiteHeader v-if="!$route.meta.hideHeaderFooter" />
    <router-view/>
    <SiteFooter v-if="!$route.meta.hideHeaderFooter" />

    <!-- Login Register Pop Up -->
    <AuthModal />
    <VideoPlayer />
  </div>
</template>
<script>
import SiteHeader from './components/SiteHeader.vue';
import SiteFooter from './components/SiteFooter.vue';
import AuthModal from './components/AuthModal.vue';
import VideoPlayer from './components/VideoPlayer.vue';
export default {
  components:{
    SiteHeader,
    SiteFooter,
    AuthModal,
    VideoPlayer,
  }
}
</script>
<style>
#app {
  font-family: 'Poppins', sans-serif;
}
</style>
