<template>
    <div v-if="isPasswordReset" class="result-success authenticationModal">
        <div @click="backToLogin()" class="align-items-center d-flex mb-3 authBack-wrap">
            <span class="authBack-btn">
                <span class="icon-arrow"></span>
            </span> 
            Login
        </div>
        <div class="form-group d-flex justify-content-center mb-3">
            <div class="btn-key-wrapper">
                <div class="icon-success"></div>
            </div>
        </div>
        <div class="form-group mb-3">
            <h2>Success!</h2>
            <p>Successfully changed a password. You can sign in now.</p>
        </div>
    </div>
    <div class="reset-password-form" v-else>
        <div class="form-group mb-3">
            <a class="back-btn" @click="backEvent()">
                <div class="btn-back-wrapper">
                    <div class="left-angle"></div>
                </div>
            </a>
        </div>
        <h2> Reset Password </h2>
        <div class="form-group mb-3">
            <label> Enter OTP <span class="required-field">*</span></label>
            <input type="text"  class="univ-input" v-model="formReq.verification_code">
            <span class="error-field" v-if="formErrors.verification_code"><span class="icon-warning"></span> {{ formErrors.verification_code }}</span>
        </div>
        <div class="form-group mb-3">
            <label>Password <span class="required-field">*</span></label>
            <input type="password"  class="univ-input" v-model="formReq.password" >
            <span class="error-field" v-if="formErrors.password"><span class="icon-warning"></span> {{ formErrors.password }}</span>
        </div>
        <div class="form-group mb-4">
            <label>Confirm Password <span class="required-field">*</span></label>
            <input type="password"  class="univ-input" v-model="formReq.password_confirmation">
        </div>
        <div class="form-group">
            <span class="error-message" v-if="formErrors.message"><span class="icon-warning"></span> {{ formErrors.message }}</span>
            <button class="univ-btn w-100" @click="resetPassword()"><b-spinner v-if="loginLoader" small></b-spinner> Reset Password </button>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import api from '@/services/api';
export default {
    props:{
        email:{
            required: true,
        }
    },
    data() {
        return {
            formReq:{
                email: this.email,
                verification_code:"",
                password: "",
                password_confirmation:"",
            },
            formErrors: {
                verification_code:"",
                password: "",
                message:"",
            },
            loginLoader: false,
            isPasswordReset:false,
        };
    },
    methods: {
        resetPassword(){
            let app = this;
            app.clearedError();
            app.loginLoader = true;
            axios.post(api.getUrl('/password-reset'),app.formReq).then((response) => {
                if(response.data.success == true){
                    app.loginLoader = false;
                    app.isPasswordReset = true;
                }else{
                    if(response.data.message){

                        app.loginLoader = false;
                        app.formErrors.message = response.data.message;

                    }else if(response.data.errors.length > 0){

                        app.loginLoader = false;
                        response.data.errors.forEach(function(item) {
                            if(item.key == "verification_code"){
                                app.formErrors.verification_code = item.message;
                            }else if(item.key == "password"){
                                app.formErrors.password = item.message;
                            }
                        }); 
                    }
                }
            });
        },
        clearedError(){
            let app = this;
            app.formErrors.verification_code= "";
            app.formErrors.password         = "";
            app.formErrors.message      = "";
        },
        backEvent(){
            let app = this;
            app.$emit('reset-password-modal', false);
        },
        backToLogin(){
            let app = this;
            app.$root.$emit("redirect-to-login", false);
        },
    }
}
</script>