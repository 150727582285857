import Vue from "vue";

import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
Vue.use(Toast);
// Bootstrap
import { BootstrapVue } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
Vue.use(BootstrapVue);

import App from "./App.vue";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "./assets/css/common.css";
import "./assets/css/icons.css";
import store from "./store";
import router from "./router";
import vuescroll from "vuescroll";
import VuePlyr from "vue-plyr";
import "vue-plyr/dist/vue-plyr.css";

import VideoBackground from "vue-responsive-video-background-player";

Vue.component("video-background", VideoBackground);
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

Vue.use(CoolLightBox);

Vue.use(vuescroll);
Vue.use(VuePlyr, {
  plyr: {},
});
import "./interceptors";

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
