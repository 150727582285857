<template>
    <b-modal id="video-player-modal" size="lg" centered>
        <div class="plyrVideo-wrap">
            <DashVideo ref="videoPlayer" :src="video_url" crossorigin />
        </div>
    </b-modal>
</template>
<script>
import DashVideo from "./DashVideo.vue";
export default {
    name:"VideoPlayer",
    components: {
        DashVideo,
    },
    data() {
        return {
            video_url:"",
        };        
    },
    mounted(){
        let app = this;
        app.$root.$on("request-video", ($event) => {
            app.video_url = $event;
        });
    },
}
</script>