<template>
    <div>
        <div :class="{ 'showcartSidebar': isSidebarVisible, 'cartSidebar': true }">
            <Cart  />
        </div>
        <header>
            <div class="container">
                <div class="row align-items-center">
                <div class="col-xl-2">
                    <div class="sitelogo" v-if="header_logo">
                        <router-link to="/"> <img :src="header_logo" class="img-fluid" alt="Logo" /></router-link>
                    </div>
                </div>
                <div class="col-xl-10">
                    <div class="site-menu">
                    <ul>
                        <li>
                            <router-link to="/">Home</router-link>
                        </li>
                        <li v-for="(item, i) in header_menus" :key="i" >
                            <router-link :to="item.slug" > {{ item.title }} </router-link>
                        </li>

                        <li v-if="isAuthenticated">
                            <router-link :to="{ name: 'MobileWishlist' }" class="cartIcon-header" v-b-tooltip >
                                <span class="icon-wishlist"></span>
                                <span class="badge-cart">{{ wishlistQuantity }}</span>
                            </router-link>
                        </li>
                        <li v-else>
                            <router-link to="" class="cartIcon-header" v-b-modal.authenticate title="My Wishlist" >
                                <span class="icon-wishlist"></span>
                            </router-link>
                        </li>
                        
                        <li v-if="isAuthenticated">
                            <router-link to="" class="cartIcon-header" v-b-tooltip.hover title="My Cart" >
                                <span class="icon-cart" @click="toggleSidebar"></span>
                                <span class="badge-cart"> {{ cartQuantity }}</span>
                            </router-link>
                        </li>
                        <li v-else>
                            <router-link to="" class="cartIcon-header" v-b-tooltip.hover title="My Cart" v-b-modal.authenticate>
                                <span class="icon-cart"></span>
                            </router-link>
                        </li>

                        <li v-if="isAuthenticated">
                            <router-link :to="{ name: 'UserDashboard' }">
                                <div class="userHead-icon logedIn">
                                    <img :src="avatar_path" :alt="user.name">
                                </div>
                            </router-link>
                        </li>
                        <li v-else>
                            <router-link to="" v-b-tooltip.hover title="Login" v-b-modal.authenticate>
                                <div class="userHead-icon">
                                    <span class="icon-login"></span>
                                </div>
                            </router-link>
                        </li>
                        <!-- <li>
                            <router-link to="" class="hightlight-btn"  v-b-modal.authenticate>Start Learning</router-link>
                        </li> -->
                    </ul>
                    </div>
                </div>
                </div>
            </div>
        </header>
        <div class="mobile-menu">
            <ul>
                <li>
                    <router-link to="/" class="highligtedMenu">
                        <span class="icon-home navIcon"></span>
                        <span class="pageName">Home</span>
                    </router-link>
                </li>
                <li v-if="isAuthenticated">
                    <router-link :to="{ name: 'MobileWishlist' }" >
                        <span class="icon-wishlist navIcon"></span>
                        <span class="badge-cart">{{ wishlistQuantity }}</span>
                    </router-link>
                </li>
                <li v-else>
                    <router-link to="" v-b-modal.authenticate >
                        <span class="icon-wishlist navIcon"></span>
                    </router-link>
                </li>

                <li v-if="isAuthenticated">
                    <router-link :to="{ name: 'MobileCart' }" class="cartIcon-header">
                        <span class="icon-cart navIcon"></span>
                        <span class="badge-cart"> {{ cartQuantity }}</span>
                    </router-link>
                </li>
                <li v-else>
                    <router-link to="" class="cartIcon-header" v-b-tooltip.hover title="My Cart" v-b-modal.authenticate>
                        <span class="icon-cart navIcon"></span>
                    </router-link>
                </li>

                <li v-if="isAuthenticated">
                    <router-link :to="{ name: 'UserDashboard' }">
                        <div class="userHead-icon logedIn">
                            <img :src="avatar_path" :alt="user.name">
                        </div>
                    </router-link>
                </li>
                <li v-else>
                    <router-link to="" v-b-tooltip.hover title="Login" v-b-modal.authenticate>
                        <div class="userHead-icon">
                            <span class="icon-login"></span>
                        </div>
                    </router-link>
                </li>
                <li @click="openBottomSheet">
                    <router-link to="" >
                        <span class="icon-menu navIcon"></span>
                    </router-link>
                </li>
            </ul>
        </div>
        <div class="bottom-sheet" v-if="isBottomSheetOpen">
            <div class="sheet-overlay" @click="closeBottomSheet"></div>
            <div class="bottom-sheet-content" :class="{ 'open-bottom-sheet': isHighlighted }">
                <div @click="closeBottomSheet" class="align-items-center d-flex mb-3 authBack-wrap">
                    <span class="authBack-btn">
                        <span class="icon-arrow"></span>
                    </span> 
                    Back
                </div>
                <div class="mobile-menu-nav">
                    <ul>
                        <li v-for="(item, i) in header_menus" :key="i" @click="closeBottomSheet" >
                            <router-link :to="item.slug" > {{ item.title }} </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import axios from 'axios'
import api from '@/services/api'
import Cart from '@/components/Cart'
import store from "@/store"
import { mapGetters} from "vuex";
export default {
  name: "SiteHeader",
  components: {
    Cart
  },
    data() {
        return {
            isSidebarVisible: false,
            isBottomSheetOpen: false,
            isHighlighted: false,
            header_logo: "",
            header_menus: [],
            isAuthenticated: store.getters.authenticated,
            user: store.getters.user,
            avatar_path: store.getters.user.avatar_path,
        };        
    },
    computed: {
        ...mapGetters(["cartQuantity","wishlistQuantity"]),
    },
    created() {
        let app = this;
        if(app.isAuthenticated){
            app.$store.dispatch("getWishlistItems",store.getters.user.id);
        }
    },
    mounted(){
        let app = this;
        app.renderHeaderInfo();
        app.$root.$on("cart-toggle", ($event) => {
            app.isSidebarVisible = $event;
        });
        app.$root.$on("authentication-status", ($event) => {
            app.isAuthenticated = $event;
            app.avatar_path = store.getters.user.avatar_path;
        });
    },
    methods: {
        toggleSidebar() {
            this.isSidebarVisible = !this.isSidebarVisible;
        },
        openBottomSheet() {
            this.isBottomSheetOpen = true;
            setTimeout(() => {
                this.isHighlighted = !this.isHighlighted;
            }, 50);
        },
        closeBottomSheet() {
            this.isBottomSheetOpen = false;
            this.isHighlighted = !this.isHighlighted;
        },
        async renderHeaderInfo() {
            let app = this;
            await axios.get(api.getUrl('/header')).then(function (response) {
                if (response.data.success) {
                    app.header_logo = response.data.data.header_logo;
                    app.header_menus = response.data.data.header_menus;
                }
            });
        },
    },
    watch: {
        $route() {
            if (store.getters.authenticated) {
                this.isAuthenticated = true;
                this.user = store.getters.user;
                this.avatar_path = store.getters.user.avatar_path;
            } else {
                this.isAuthenticated = false;
            }
        }
    }
};
</script>
