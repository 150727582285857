import axios from "axios";
import router from "@/router";
import Helper from "./services/helper";

axios.interceptors.response.use(
  function (response) {
    if (response.data.success === false) {
      if (
        response.data.message ===
        "Unauthorized - User is already logged in another device."
      ) {
        Helper.logout();
        if (router.currentRoute.name != "NotAllowed") {
          router.push("/not-allowed");
        }
      }
    }
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);
