import axios from 'axios';
import api from '@/services/api'
const state = {
    wishlistItems: {},
    courseWishlist:[],
    assetWishlist:[],
    wishlistBtnLoading: false,
}
const mutations = {
    UPDATE_WISHLIST_ITEMS (state, payload) {
        state.wishlistItems = payload;
        state.wishlistBtnLoading = false;
    },
    UPDATE_WISHLIST_BTN_STATUS (state, status) {
        state.cartBtnLoading = status;
    },
}
const actions = {
    getWishlistItems ({ commit }, userID) {
        axios.get(api.getUrl('/user/wishlist/'+userID)).then((response) => {
            if(response.data.success){
                commit('UPDATE_WISHLIST_ITEMS', response.data.data)
            }
        });
    },
    addWishlistItem ({ commit }, item) {
        commit('UPDATE_WISHLIST_BTN_STATUS', true)
        axios.post(api.getUrl('/user/wishlist/store'), item).then((response) => {
            if(response.data.success){
                commit('UPDATE_WISHLIST_ITEMS', response.data.data)
            }
        });
    },
    removeWishlistItem ({ commit }, item) {
        axios.post(api.getUrl('/user/wishlist/removed'),{ user_id:item.user_id, item_id:item.item_id, item_type:item.item_type }).then(function (response) {
            if(response.data.success){
                commit('UPDATE_WISHLIST_ITEMS', response.data.data)
            }
        }).catch(()=>{});
    },
}
const getters = {
    wishlistItems: state => state.wishlistItems,

    wishBtnLoading: state => state.wishlistBtnLoading,
    wishlistQuantity: state => {
        if(Array.isArray(state.wishlistItems)){
            return state.wishlistItems.reduce((acc) => {
                return 1 + acc;
            }, 0);
        }return 0;
    },
    wishlistItemSummary: state => {
        state.itemsSummary = [];
        if(Array.isArray(state.wishlistItems)){
            state.wishlistItems.forEach((value) => {
                let item = {};
                item.item_id        =  value.item_id;
                item.item_type      =  value.item_type;
                item.price          =  value.price;
                item.offer_price    =  value.offer_price;
                item.qty            =  value.quantity;
                state.itemsSummary.push(item);
            });
        }
        return state.itemsSummary;
    },
    courseWishlistItems: state => {
        if(Array.isArray(state.wishlistItems)){
            state.wishlistItems.forEach((value) => {
                if(value.item_type ==='course'){
                    state.wishlistCart.push(value.item_id);
                }
            });
        }
        return state.courseWishlist;
    },
    assetWishlistItems: state => {
        state.assetWishlist = [];
        if(Array.isArray(state.wishlistItems)){
            state.wishlistItems.forEach((value) => {
                if(value.item_type ==='asset'){
                    state.assetWishlist.push(value.item_id);
                }
            });
        }
        return state.assetWishlist;
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}