<template>
    <div class="optVerificationFrom">  
        <div v-if="otpVerified" class="result-success">
            <div class="form-group d-flex justify-content-center mb-3">
                <div class="btn-key-wrapper">
                    <div class="icon-success"></div>
                </div>
            </div>
            <div class="form-group mb-3">
                <h2>Success!</h2>
                <p>Successfully verified your account. You can sign in now. </p>
            </div>
        </div>
        <div v-else>                     
            <div class="form-group mb-3">
                <label> Enter verification code <span class="required-field">*</span></label>
                <p>Please check your email to get a verification code.</p>
                <input type="text"  class="univ-input" v-model="otpReq.verification_code">
                <span class="error-field" v-if="otpErrors.verification_code"><span class="icon-warning"></span> {{ otpErrors.verification_code }}</span>
            </div>
            <div class="form-group">
                <span class="error-message" v-if="otpErrors.message"><span class="icon-warning"></span> {{ otpErrors.message }}</span>
                <button class="univ-btn w-100" @click="otpVerification()"><b-spinner v-if="loginLoader" small></b-spinner> Verify </button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import api from '@/services/api';
export default {
    props:{
        email:{
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            otpReq:{
                email: this.email,
                verification_code:"",
            },
            otpErrors: {
                email: "",
                verification_code:"",
                message:"",
            },
            loginLoader: false,
            optVerificationFrom: false,
            otpVerified:false,
        };
    },
    methods: {
        otpVerification(){
            let app = this;
            app.clearedError();
            app.loginLoader = true;
            axios.post(api.getUrl('/verification'),app.otpReq).then((response) => {
                if(response.data.success == true){

                    app.loginLoader = false;
                    app.optVerificationFrom = true;
                    app.otpVerified = true;
                    
                }else{
                    if(response.data.message){

                        app.loginLoader = false;
                        app.otpErrors.message = response.data.message;

                    }else if(response.data.errors.length > 0){

                        app.loginLoader = false;
                        response.data.errors.forEach(function(item) {
                            if(item.key == "verification_code"){
                                app.otpErrors.verification_code = item.message;
                            }
                        }); 
                    }
                }
            });
        },
        clearedError(){
            let app = this;
            app.otpErrors.email = "";
            app.otpErrors.verification_code = "";
        },
        backToLogin(){
            let app = this;
            app.$emit("redirect-to-register", false);
        },
    }
}
</script>