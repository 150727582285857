import store from "@/store";
import Helper from "@/services/helper";
const userRoutes = [
    {
      path: '/videoplay/:course/:curriculum/:lesson',
      name: 'LessonVideos',
      component: () => import('../views/Courses/LessonVideos.vue'),
      meta: {
        hideHeaderFooter: true
      }
    },
    {
      path: '/dashboard',
      name: 'UserDashboard',
      beforeEnter: requiredAuthentication,
      component: () => import('@/views/User/UserDashboard'),
    },
    {
      path: '/cart',
      name: 'MobileCart',
      beforeEnter: requiredAuthentication,
      component: () => import('../components/MobileCart.vue')
    },
    {
      path: '/wishlist',
      name: 'MobileWishlist',
      beforeEnter: requiredAuthentication,
      component: () => import('../components/MobileWishlist.vue')
    },
    {
      path: '/cart/checkout',
      name: 'CartCheckout',
      beforeEnter: requiredAuthentication,
      component: () => import('@/views/User/CartCheckout'),
    },
    {
      path: '/:payment/success',
      name: 'PaymentSuccess',
      beforeEnter: requiredAuthentication,
      component: () => import('@/views/Payments/Success'),
    },
    {
      path: '/:payment/fail',
      name: 'Failure',
      beforeEnter: requiredAuthentication,
      component: () => import('@/views/Payments/Failure'),
    },
    {
      path: "/logout",
      name: "Logout",
      beforeEnter: (to, from, next) => {
        Helper.logout()
        next({
          path: "/"
        })
      }
    },
]
function requiredAuthentication(to, from, next){
  if(store.getters.accessToken && store.getters.authenticated){
    return next();
  }
  return next('/');
}
export default userRoutes;